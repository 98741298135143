// // src/components/googleAdsense.js

// import React, { useEffect } from 'react';

// export const Adsense = ({ path, onLoad }) => {
//   useEffect(() => {
//     (window.adsbygoogle = window.adsbygoogle || []).push({});
//     if (onLoad) {
//       onLoad();
//     }
//   }, [path, onLoad]);

//   return (
//     <ins 
//       className="adsbygoogle"
//       style={{ display: "block", textAlign: "center" }}
//       data-ad-client="ca-pub-2292291997787849"
//       data-ad-slot="4265880199"
//       data-ad-format="auto"
//       data-full-width-responsive="true"
//     />
//   );
// };

// src/components/googleAdsense.js

import React, { useEffect } from 'react'

export const Adsense = ({ path }) => {
  useEffect(() => {
    // console.log("From Component");
    ;(window.adsbygoogle = window.adsbygoogle || []).push({});
  }, [path])
  
  return (
    <ins 
      className="adsbygoogle"
      style={{ "display": "block" , textAlign: "center"  }}
      data-ad-client="ca-pub-2292291997787849"
      data-ad-slot="4265880199"
      data-ad-format="auto"
      data-full-width-responsive="true"
    />
  )
}